<template>
    <div class="disease-detail xi" >
        <div class="topfixed">
            <hd-com></hd-com>
            <!-- <div class="banner">
                <div class="banner-text">
                    <h3>{{ diseaseDetail.diseaseName }}<span v-if="diseaseDetail.diseaseAlias">（别名：{{diseaseDetail.diseaseAlias}}）</span></h3>
                    <p>科室：<i>{{ diseaseDetail.category }}</i></p>
                    <div class="banner-right">
                        <span v-if="!exist" @click="onCollection(diseaseDetail)">加入收藏</span>
                        <span v-else @click="deleteAll()">取消收藏</span>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="content-wrap">
            <div class="content clearfix">
                <div class="breadcrumb">
                    <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><router-link
                            to="/zdrugconsultation/zDrugPage">辨证论治
                        </router-link><i class="el-icon-arrow-right"></i><a>{{ infoList.syndromeName }}</a></p>
                </div>
                <div class="detail-box" >
                    <div class="detail-top clearfix">
                        <div class="left beforename" style="margin-left: 0;margin-right: 15px;">
                            <p>{{ infoList.syndromeName }}</p>
                        </div>
                        <div class="left dyname" v-for="(item, index) in infoList.syndromeInfo"
                            :key="index">
                            <p><i>#</i>{{ item.title }}</p>
                        </div>
                    </div>
                    <div class="zztabfel" data-size="16" v-for="(menu, index) in infoList.syndromeInfo" :key="index">
                        <div class="sd-div">
                            <h3 class="sd-tit " data-size="24">
                                {{ menu.title }}
                        
                            </h3>
                            <div class="sd-mes " data-size="16">
                                <p
                                    v-html="math(menu.info.toString().replace(/\n/g, '</p> <p>'))">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="" style="color: #b76c12;text-align: center;cursor: pointer;" @click.stop="goprimaryZdetail">
                        查看更多
                    </div>
                </div>
                <el-scrollbar class="left-nav-wrap">
                    <div class="fast-nav">
                        <ul class="timeline is-reverse">
                            <div v-for="(menu, index) in  infoList.syndromeInfo" :key="index" @click="jump(index)"
                                :class="index == 0 ? 'active' : ''">
                                <li class="timeline-item">
                                    <div class="timeline-item__node ">
                                    </div>
                                    <div class="timeline-item__text" data-size="17">
                                        <h3>{{ menu.title }}</h3>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
import hdCom from '../../components/header' //引入组件
import ftCom from '../../components/footer' //引入组件
import rtBar from "../../components/right";
import { zdrugconsultationMain } from "../../components/zdrugconsultation/zdrugconsultation";
import { DiseaseUtility, replaceContent, checkNameAndPath, toSearchList, toDetailInfo } from "../../utils/diseaseUtility";
export default {
    name: "DiseaseDetail",
    components: {
        hdCom,
        ftCom,
        rtBar,
    },
    data() {
        var zdrugconsultation = new zdrugconsultationMain(this.TokenClient, this.Services.Disease)
        return {
            zdrugconsultationMain:zdrugconsultation,
            activeName: ['index'],
            ifOver: true, // 文本是否超出三行，默认否
            unfold: false, // 文本是否是展开状态 默认为收起
            diseaseHashKey: this.$route.query.hashKey, //列表页id
            diseaseDetail: {}, //初始化详情
            menus: {},  //疾病菜单
            scroll: '',
            entityData: {},
            dialogVisible: false,
            showall: false, //刚开始没有激活
            bussinessHeight: '',
            exist: false,
            collectionId: "",
            infoList:{}

        };
    },
     mounted() {
             let _this = this;
        window.getPageInfo = _this.getPageInfo;
        this.getList()
    },
    methods: {
        goprimaryZdetail() {
            var _this = this
            if (_this.diseaseHashKey) {
                   _this.$router.push({
                        name: "diseaseDetail",
                        query: {
                        hashKey: _this.diseaseHashKey,
                        searchType: 'disease'
                        },
                    });
            } else {
                 _this.$message.error('暂未关联疾病');
            }
        },
         math(info) {
            return replaceContent(info);
        },
        handleScroll() {
            let scrollY = document.documentElement.scrollTop ||
                window.pageYOffset ||
                document.body.scrollTop;
            let scrollObj = document.querySelector(".left-nav-wrap");
            let topfixed = document.querySelector(".topfixed");
            //scrollObj 这个是下图右边答题卡的div

            if (scrollY > 80) {
                topfixed.style.position = "fixed";
                topfixed.style.top = 0;
                topfixed.style.zIndex = 99;
                scrollObj.style.position = "fixed";
                scrollObj.style.top = "200px";
            } else {
                topfixed.style.position = "initial";
                topfixed.style.top = "none";
                scrollObj.style.position = "absolute";
                scrollObj.style.top = "auto";
            }
        },
        getList(){
             var _this = this;
            _this.zdrugconsultationMain.getSelfDiagnosisSyndrome(_this.$route.query.id, function (data) {
                _this.infoList = data.data
            
            }, function (error) {
                console.log(error)
            })
        },
         getPageInfo(name, type, hashKey) {
            if (hashKey == 'Search') {
                toSearchList(checkNameAndPath(type).typeName, name)
            } else {
                if (type == "Disease") {
                    this.hashKey = hashKey
                    this.getDiseaseDetail()
                } else {
                    toDetailInfo(checkNameAndPath(type).path, hashKey, type)
                }

            }
        },
        checkExist() {
            var _this = this;
            _this.collectionDomain.Exist(this.entityData.type, this.entityData.hashKey, this.entityData.id,
                function (data) {
                    _this.exist = data.data.exist
                    _this.collectionId = data.data.collectionId

                }, function (err) {

                });
        },
        CopyLink(data) {
            // var editItem = this.tableData[index];
            var copyData = data.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '');
            this.copy(copyData)
        },
        copy(data) {
            let url = data;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success'
            });
            oInput.remove()
        },
        dataScroll: function () {
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
        },
        jump(index) {
            let jump = document.getElementsByClassName('zztabfel');
            // 获取需要滚动的距离
            let total = jump[index].offsetTop - 200;
            window.scroll(0, total)
            var $navs = $(".timeline div");
            $navs.eq(index).addClass("active").siblings().removeClass("active")

        },
        loadSroll: function () {
            var self = this;
            var $navs = $(".timeline>div");
            var sections = document.getElementsByClassName('zztabfel');
            for (var i = sections.length - 1; i >= 0; i--) {
                if (self.scroll >= sections[i].offsetTop - 200) {
                    $navs.eq(i).addClass("active").siblings().removeClass("active")
                    break;
                }
            }
        },
    }
}
//过滤html标签
function filter(html) {
    return html
        .replace(/<(?:.|\n)*?>/gm, '')
        .replace(/(&rdquo;)/g, '"')
        .replace(/&ldquo;/g, '"')
        .replace(/&mdash;/g, '-')
        .replace(/&nbsp;/g, '')
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/<[\w\s"':=\/]*/, '')
}
</script>

<style scoped>
@import "../../assets/css/header.css";
@import "../../assets/css/footer.css";

/*收缩框*/
.showall {
    padding: 10px;
    height: 300px;
    overflow: hidden;
    clear: both;
    background: #f5f5f5;
    margin-top: 6px
}

.showall.active {
    height: auto;
    background: #fff;
    min-height: 150px;
}

.readAllBtn {
    position: relative;
    height: 78px;
    margin-top: -68px;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), #ffffff);
    text-align: center;
}

.xi .readAllBtn .btn-text {
    position: absolute;
    bottom: 0;
    color: #1fb7a6;
    cursor: pointer;
}

.zhong .readAllBtn .btn-text {
    position: absolute;
    bottom: 0;
    color: #b76c12;
    cursor: pointer;
}

.xi .banner {
    width: 100%;
    height: 116px;
    background: url("../../assets/img/jbk_banner.png")center no-repeat;
}

.zhong .banner {
    width: 100%;
    height: 116px;
    background: #b76c12;
}

.banner-text {
    position: relative;
    width: 1200px;
    margin: 0 auto;
}

.banner-text h3 {
    padding-top: 25px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text h3 span {
    font-size: 14px;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
}

.banner-text p i {
    font-style: normal;
}

.banner-right {
    position: absolute;
    right: 5px;
    top: 45px;
}

.banner-right span {
    display: inline-block;
    margin-left: 20px;
    width: 132px;
    height: 32px;
    border: 1px solid #ffffff;
    border-radius: 32px;
    box-sizing: border-box;
    text-align: center;
    color: #ffffff;
    line-height: 30px;
    cursor: pointer;
}

.banner-right span:hover {
    background-color: #ffffff;
    color: #00ab98;
}

.zhong .banner-right span:hover {
    background-color: #ffffff;
    color: #b76c12;
}

.content-wrap {
    background: #f8f8f8;
}

.content-wrap .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 60px;
    background: none;
}

.breadcrumb p {
    padding: 20px 0;
    color: #999999;
    font-size: 16px;
}

.breadcrumb p a {
    color: #999999;
    font-size: 16px;
    margin: 0 5px;
}

.detail-box {
    float: left;
    padding: 30px 35px;
    width: 830px;
    background: #FFFFFF;
    box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
}

.detail-top {
    padding: 15px 10px;
    background: #f8f8f8;
}

.detail-top .dyimg {
    text-align: center;
}

.detail-top .dyimg p {
    font-size: 12px;
    color: #333333;
}

.detail-top .beforename {
    margin-right: 20px;
    padding: 0 10px;
    height: 30px;
    border-radius: 30px;
    line-height: 30px;
    background: #f0f0f0;
    font-size: 14px;
    color: #333333;
}

.detail-top .dyname {
    margin-left: 20px;
    padding: 0 10px;
    height: 30px;
    border-radius: 30px;
    line-height: 30px;
    background: #f0f0f0;
    font-size: 14px;
    color: #333333;
    cursor: pointer;
}

.detail-top .dyname p i {
    margin-right: 10px;
    display: inline-block;
    color: #e62828;
    font-size: 12px;
    background-color: #FFFFFF;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    font-style: normal;
}

.detail-top .dyname p:hover {
    color: #1fb7a6;
    text-decoration: underline;
}

.sd-div {
    padding: 30px 0;
    border-top: 1px solid #e5e5e5;
}

.sd-div .sd-tit {
    position: relative;
    font-size: 24px;
    color: #333333;
    font-weight: normal;
    margin-bottom: 30px;
}

.sd-div .sd-tit.overview {
    font-size: 26px;
    color: #333333;
    padding-left: 15px;
    border-left: 3px solid #333;
    line-height: 26px;
}

.sd-div .sd-tit>.el-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

/* .sd-div .sd-mes p {
    text-indent: 2em;
    color: #333333;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px;
} */

.sd-div .sd-mes {
    /* max-height: 200px; */
    overflow: hidden;
    text-overflow: ellipsis;
}

.sd-div .sd-mes.all {
    max-height: initial;
}

.sd-mes-img {
    display: inline-block;
    margin: 15px;
}

.divider {
    position: relative;
    display: block;
    height: 1px;
    width: 100%;
    margin: 24px 0;
    border-top: 1px dashed #999999;
}

.divider .divider-text {
    position: absolute;
    background-color: #fff;
    font-weight: 500;
    color: #333333;
    font-size: 20px;
    transform: translateY(-50%);
    line-height: 35px;
}

.divider .divider-text span {
    color: #00ad9a;
    font-size: 12px;
    line-height: 35px;
}

.divider-content {
    padding: 0 0 15px;
}

.divider-content p {
    /* font-size: 16px; */
    color: #333333;
}

.bybj-item {
    padding: 15px;
    border: 1px solid #f5f5f5;
    box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
}

.bybj-tit {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
}

.bybj-txt {
    font-size: 16px;
    color: #666666;
    margin-top: 20px;
}

.bybj-txt p {
    color: #666666;
}

.over-hidden {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.status-btn {
    color: #00ab98;
    cursor: pointer;
}

.left-nav-wrap {
    position: absolute;
    margin-left: 950px;
    /*max-height: 500px;*/
    max-height: calc(100vh - 260px - 205px);
    /* border: 1px solid #e5e5e5; */
    box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
    background: #FFFFFF;
}

/deep/ .el-scrollbar .el-scrollbar__wrap {
    max-height: 500px;
    overflow-x: hidden;
}

.fast-nav {
    width: 250px;
    padding: 20px 0 20px 40px;
    box-sizing: border-box;
    z-index: 10;
}

.timeline-item {
    position: relative;
    padding-bottom: 17px;
    padding-top: 8px;
}

.timeline-item__tail {
    position: absolute;
    top: 15px;
    left: 3px;
    height: 100%;
    border-left: 1px solid #e5e5e5;
}

.timeline-item__node {
    position: absolute;
    background-color: #cccccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 15px;
    left: -1px;
    width: 9px;
    height: 9px;
}

.timeline-item__text {
    position: relative;
    padding-left: 28px;
    top: -3px;
    cursor: pointer;
}

.timeline-item__text h3 {
    font-size: 17px;
    color: #333;
    font-weight: normal;
}

.timeline-item__text p {
    line-height: 2.5;
    color: #565656;
    font-weight: normal;
}

.xi .timeline div.active .timeline-item__node,
.xi .timeline div:hover .timeline-item__node {
    background-color: #1fb7a6;
}

.xi .timeline div.active .timeline-item__text h3,
.xi .timeline div:hover .timeline-item__text h3 {
    color: #1fb7a6;
}

.zhong .timeline div.active .timeline-item__node,
.zhong .timeline div:hover .timeline-item__node {
    background-color: #b76c12;
}

.zhong .timeline div.active .timeline-item__text h3,
.zhong .timeline div:hover .timeline-item__text h3 {
    color: #b76c12;
}

.xi .more {
    float: right;
    color: #1fb7a6;
    border: 1px solid #1fb7a6;
    border-radius: 13px;
    padding: 0 10px;
}

.xi .more:hover {
    cursor: pointer;
    color: #ffffff;
    border: 1px solid #1fb7a6;
    background: #1fb7a6;
}

.zhong .more {
    float: right;
    color: #b76c12;
    border: 1px solid #b76c12;
    border-radius: 13px;
    padding: 0 10px;
}

.zhong .more:hover {
    cursor: pointer;
    color: #ffffff;
    border: 1px solid #b76c12;
    background: #b76c12;
}
</style>
<style>
.disease-detail .bar-list .yysc {
    display: none !important;
}

.disease-detail .el-image__inner {
    height: 160px;
    width: auto;
}

.disease-detail .el-collapse-item__header {
    background-color: #1fb7a6;
    color: #ffffff;
    font-size: 18px;
    padding: 0 20px;
}

.disease-detail.zhong .el-collapse-item__header {
    background-color: #b76c12;
    color: #ffffff;
    font-size: 18px;
    padding: 0 20px;
}

.disease-detail .el-collapse-item__wrap {
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;
}

.disease-detail .el-collapse-item__content {
    padding: 15px 5px;
}

.sd-div .sd-mes p a {
    color: #1fb7a6 !important;
}

.zhong .sd-div .sd-mes p a {
    color: #b76c12 !important;
}

.zhong .el-collapse-item a {
    color: #b76c12 !important;
}

.el-scrollbar__wrap {
    overflow: auto;
}
</style>
