/*
 * @Author: your name
 * @Date: 2021-06-04 18:20:13
 * @LastEditTime: 2021-07-01 14:51:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \qianyue\src\components\Disease\Disease.js
 */
let zdrugconsultationMain = function (tokenClient, serviceAddr) {
  let TokenClient = tokenClient;
  let ServiceBaseAddress = serviceAddr;
  // 已启用分类
  this.getSelfDiagnosisCategory = function (categoryId, success, error) {
    var url = ServiceBaseAddress + "/api/SelfDiagnosisCategory/Enable/SelfDiagnosis/" + categoryId;
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.getSelfTCMDiseaseCategory = function (categoryId, success, error) {
    var url = ServiceBaseAddress + "/api/SelfDiagnosisCategory/Enable/TCMDisease/" + categoryId;
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };

  // 主症详情
  this.getSelfDiagnosisPrimarySymptomDetail = function (categoryId, success, error) {
    var url = ServiceBaseAddress + "/api/SelfDiagnosisPrimarySymptom/" + categoryId;
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  //主症列表
  this.getSelfDiagnosisPrimarySymptom = function (categoryId, keyword, gender, age, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/SelfDiagnosisPrimarySymptom/SelfDiagnosis/Category/${categoryId}/${keyword}/${gender}/${age}/${pageIndex}`;
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.getSelfTCMDiseasePrimarySymptom = function (categoryId, keyword, gender, age, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/SelfDiagnosisPrimarySymptom/TCMDisease/Category/${categoryId}/${keyword}/${gender}/${age}/${pageIndex}`;
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };

  // 题库
  this.getSelfDiagnosisQuestion = function (selfId, isGender, age, success, error) {
    var url = ServiceBaseAddress + "/api/SelfDiagnosisQuestion/PrimarySymptom/" + selfId + "/" + isGender + "/" + age;
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
      // 大屏列表
      this.getSelfDiagnosisHardware = function (startTime, endTime, keyWord, pageIndex, diseaseName, primarySymptomName, item, success, error) {
        var url = ServiceBaseAddress + "/api/UserSelfDiagnosisRecord/SelfDiagnosisHardware";
        if (startTime) {
          url += `/${startTime}`;
        } else {
          url += "/%20";
        }
        if (endTime) {
          url += `/${endTime}`;
        } else {
          url += "/%20";
        }
        if (keyWord) {
          url += `/${keyWord}`;
        } else {
          url += `/%20`;
        }
        if (pageIndex) {
          url += `/${pageIndex}`;
        }
        if (diseaseName) {
          url += `/${diseaseName}`;
        } else {
          url += `/%20`;
        }
        if (primarySymptomName) {
          url += `/${primarySymptomName}`;
        } else {
          url += `/%20`;
        }
        TokenClient.Post(
          url,
          true,
          { "Content-Type": "application/json" },
          item,
          function (data) {
            if (success) {
              var jsonObj = JSON.parse(data);
              success(jsonObj);
            }
          },
          error
        );
      };
  // 提交答案
  this.getSelfDiagnosisQuestionSubmit = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/SelfDiagnosisQuestion/Submit";
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  // 问诊结果
  this.getUserSelfDiagnosisRecordResult = function (categoryId, success, error) {
    var url = ServiceBaseAddress + "/api/UserSelfDiagnosisRecord/Result/" + categoryId;
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  // 问诊过程
  this.getUserSelfDiagnosisRecordInfo = function (categoryId, success, error) {
    var url = ServiceBaseAddress + "/api/UserSelfDiagnosisRecord/Info/" + categoryId;
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  // 问诊列表
  this.getUserTCMDiseaseRecord = function (startTime, endTime, keyWord, pageIndex, success, error) {
    var url = ServiceBaseAddress + "/api/UserSelfDiagnosisRecord/TCMDisease";
    if (startTime) {
      url += `/${startTime}`;
    } else {
      url += "/%20";
    }
    if (endTime) {
      url += `/${endTime}`;
    } else {
      url += "/%20";
    }
    if (keyWord) {
      url += `/${keyWord}`;
    } else {
      url += `/%20`;
    }
    if (pageIndex) {
      url += `/${pageIndex}`;
    }
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };

  // 问诊列表
  this.getUserSelfDiagnosisRecord = function (startTime, endTime, keyWord, pageIndex, diseaseName, primarySymptomName, success, error) {
    var url = ServiceBaseAddress + "/api/UserSelfDiagnosisRecord/SelfDiagnosis";
    if (startTime) {
      url += `/${startTime}`;
    } else {
      url += "/%20";
    }
    if (endTime) {
      url += `/${endTime}`;
    } else {
      url += "/%20";
    }
    if (keyWord) {
      url += `/${keyWord}`;
    } else {
      url += `/%20`;
    }
    if (pageIndex) {
      url += `/${pageIndex}`;
    }
    if (diseaseName) {
      url += `/${diseaseName}`;
    } else {
      url += `/%20`;
    }
    if (primarySymptomName) {
      url += `/${primarySymptomName}`;
    } else {
      url += `/%20`;
    }
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.getHardwareByUser = function (orgId, success, error) {
    var url = ServiceBaseAddress + "/api/Hardware/HardwareByUser/" + orgId;
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };


  // 问诊删除
  this.getUserSelfDiagnosisRecordDelete = function (categoryId, success, error) {
    var url = ServiceBaseAddress + "/api/UserSelfDiagnosisRecord/" + categoryId;
    TokenClient.Delete(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  // 证候结果
  this.getSelfDiagnosisSyndrome = function (categoryId, success, error) {
    var url = ServiceBaseAddress + "/api/SelfDiagnosisSyndrome/" + categoryId;
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 症状查询获取疾病列表数据
   * @param {*} isClinical
   * @param {*} category
   * @param {*} pageIndex
   * @param {*} department
   * @param {*} keyword
   * @param {*} success
   * @param {*} error
   */
  this.Clinicals = function (isClinical, category, pageIndex, department, keyword, success, error) {
    var url = ServiceBaseAddress + "/api/Diseases/Search";
    if (isClinical || isClinical == 0) {
      url += `/${isClinical}`;
    }
    if (category) {
      url += `/${category}`;
    }
    if (pageIndex) {
      url += `/${pageIndex}`;
    }
    if (department || department == 0) {
      url += `/${department}`;
    }
    if (keyword) {
      url += `/${keyword}`;
    } else {
      url += "/%20";
    }
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   *
   * @param {*} category
   * @param {*} success
   * @param {*} error
   */
  this.DiseaseDepartmentList = function (category, success, error) {
    var url = ServiceBaseAddress + "/api/Diseases/Categories";
    if (category) {
      url += `/${category}`;
    }
    TokenClient.Get(
      url,
      false,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取疾病详情数据
   * @param {any} success
   * @param {any} error
   */
  this.Detail = function (params, success, error) {
    var url = ServiceBaseAddress + "/api/Diseases/" + params;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };

  // 问诊列表
//   this.getUserTCMDiseaseRecord = function (startTime, endTime, keyWord, pageIndex, success, error) {
//     var url = ServiceBaseAddress + "/api/UserSelfDiagnosisRecord/TCMDisease";
//     if (startTime) {
//       url += `/${startTime}`;
//     } else {
//       url += "/%20";
//     }
//     if (endTime) {
//       url += `/${endTime}`;
//     } else {
//       url += "/%20";
//     }
//     if (keyWord) {
//       url += `/${keyWord}`;
//     } else {
//       url += `/%20`;
//     }
//     if (pageIndex) {
//       url += `/${pageIndex}`;
//     }
//     TokenClient.Get(
//       url,
//       false,
//       null,
//       null,
//       function (data) {
//         if (success) {
//           var jsonObj = JSON.parse(data);
//           success(jsonObj);
//         }
//       },
//       error
//     );
//   };
}

export { zdrugconsultationMain }